<template>
<b-container fluid>
  <bo-page-title />
  <section id="career">
    <b-tabs v-if="isList">
      <b-tab title="Page Settings">
        <HeroSection :modulePage="modulePage" v-if="moduleRole('hero')" />

        <b-card>
          <b-card-header>
            <b-card-title title-tag="h5">Section Work with Us</b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="d-flex justify-content-end">
              <button @click="openModalTitle(titleWorkWithUs, 'career-work-with-us-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                Update Content <i class="fas fa-pencil-alt ml-2"></i>
              </button>
            </div>

            <div class="wrap_title_desc mt-2">
              <h4>{{ titleWorkWithUs.asc_title_id }}</h4>
              <p>{{ titleWorkWithUs.asc_sub_title_id }}</p>
            </div>
            <b-row>
              <b-col md="4" v-for="(v, k) in work_with_us.asc_data" :key="`wwu-${k}`">
                <div class="career_benefit bg-white rounded-10 h-full">
                  <div class="cb-content p-20 flex flex-col items-center">
                    <div class="cb-icon mb-0/5em">
                      <img :src="uploader(v.image)" alt="" width="80" height="80">
                    </div>
                    <h3 class="text-24 font-semibold mt-3">{{ v.title_en }}</h3>
                    <span class="text-14 leading-1/5em">{{ v.desc_en }}</span>
                  </div>

                  <div class="main-card-footer">
                    <div class="d-flex">
                      <div class="ml-auto">
                        <b-button class="btn-icon" variant="outline-warning" v-b-tooltip.hover="'Update Data'" @click="editWorkWithUs(v)" type="button">
                          <i class="fas fa-pencil-alt"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-modal id="workWithUs" size="md" title="Update Data">
            <validation-observer ref="VFormWorkWithUs" v-slot="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(submitEnvironment)">
                <b-form-group>
                  <template #label>Image <span class="text-danger">*</span></template>
                  <Uploader v-model="dataModal.image" type="about_environment" />
                  <VValidate name="Image" v-model="dataModal.image" rules="required" />
                </b-form-group>

                <b-form-group>
                  <template #label>Title (ID) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModal.title_id"></b-form-input>
                  <VValidate name="Title (ID)" v-model="dataModal.title_id" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Title (EN) <span class="text-danger">*</span></template>
                  <b-form-input v-model="dataModal.title_en"></b-form-input>
                  <VValidate name="Title (EN)" v-model="dataModal.title_en" rules="required|min:3|max:150" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (ID) <span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModal.desc_id"></b-form-textarea>
                  <VValidate name="Description (ID)" v-model="dataModal.desc_id" rules="required|min:3" />
                </b-form-group>
                <b-form-group>
                  <template #label>Description (EN) <span class="text-danger">*</span></template>
                  <b-form-textarea v-model="dataModal.desc_en"></b-form-textarea>
                  <VValidate name="Description (EN)" v-model="dataModal.desc_en" rules="required|min:3" />
                </b-form-group>
              </b-form>
            </validation-observer>
            <template #modal-footer>
              <b-button type="button" variant="secondary" @click="$bvModal.hide('workWithUs')">Cancel</b-button>
              <b-button type="button" variant="primary" @click="submitWorkWithUs">Submit</b-button>
            </template>
          </b-modal>
        </b-card>

        <b-card no-body v-if="moduleRole('vacancies')">          
          <b-card-header>
            <b-row class="justify-content-between">
              <b-col md=3>
                <b-card-title title-tag="h5">Job Vacancies</b-card-title>
              </b-col>
              <b-col md=3 class="text-right">
                <b-button variant="success" class="m-l-15 btn-rounded" :to="{ name: routeName, params: { pageSlug: 'add' } }">
                  <i class="fa fa-plus-circle mr-2" />
                  Add Job Vacancies
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>

          <b-card-body>
            <div class="d-flex justify-content-end">
              <button @click="openModalTitle(titleJobVacancy, 'career-job-vacancy-title')" type="button" class="btn btn-rounded ml-auto btn-outline-info" variant="secondary" size="sm" pill>
                Update Content <i class="fas fa-pencil-alt ml-2"></i>
              </button>
            </div>
  
            <div class="wrap_title_desc mt-2">
              <h4>{{ titleJobVacancy.asc_title_id }}</h4>
              <p>{{ titleJobVacancy.asc_sub_title_id }}</p>
            </div>
          </b-card-body>

          <b-card-body>
            <b-row class="gutter-2" v-if="(data.data||[]).length">
              <b-col md="4" v-for="(career, key) in (data.data||[])" :key="key">
                <div class="main-card main-card--sm career-card">
                  <div class="main-card-header" v-if="false">
                    <h3 class="career-card__title">
                      <template v-if="career.mc_type == 'fulltime'">Full Time</template>
                      <template v-else-if="career.mc_type == 'parttime'">Part Time</template>
                      <template v-else-if="career.mc_type == 'internship'">Intership</template>
                    </h3>
                  </div>
                  <div class="main-card-body">
                    <div class="career-reqs">
                      <b-row class="gutter-1">
                        <b-col md="12">
                          <div class="d-flex align-items-center justify-content-between">
                            <h4 class="font-weight-bold mb-0">{{career.mc_job_name}}</h4>

                            <b-badge :variant="career.mc_is_active == 'N' ? 'danger' : 'success'">
                              {{career.mc_is_active == 'Y' ? 'Active' : 'Inactive'}}
                            </b-badge>
                          </div>

                        </b-col>
                        <b-col md="12" v-if="false">
                          <div class="career-req__item">
                            <i class="icon-location-pin"></i>
                            <span>{{career.mc_location}}</span>
                          </div>
                        </b-col>
                        <b-col md="12" v-if="false">
                          <div class="career-req__item">
                            <i class="icon-bag"></i>
                            <span>{{career.mc_job_name}}</span>
                          </div>
                        </b-col>
                        <b-col md="12" v-if="false">
                          <div class="career-req__item">
                            <i class="icon-clock"></i>
                            <span>{{career.mc_start_date}} s.d. {{ career.mc_end_date }}</span>
                          </div>
                        </b-col>

                        <b-col md="12" class="mt-3 mb-3">
                          <div class="border p-3 text-muted content-job">
                            <span v-html="career.mc_requirement_id"></span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="main-card-footer">
                    <div class="d-flex">
                      <div class="ml-auto">
                        <b-button class="btn-icon" variant="outline-warning" v-if="moduleRole('edit')" :to="{ name: routeName, params: { pageSlug: career.mc_id } }" v-b-tooltip.hover="'Update Vacancy'">
                          <i class="fas fa-pencil-alt"></i>
                        </b-button>

                        <b-button class="btn-icon ml-1" variant="outline-info" @click="doChangeStatus(key,career)" v-if="moduleRole('status')" v-b-tooltip.hover="'Update Vacancy Status'">
                          <i class="fas fa-cog"></i>
                        </b-button>

                        <b-button class="btn-icon ml-1" variant="outline-danger" @click="doDelete(key,career)" v-if="moduleRole('delete')" v-b-tooltip.hover="'Delete Vacancy'">
                          <i class="fas fa-trash-alt"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <div class="text-center m-auto">
                <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                <h4 align="center">There is no data</h4>
              </div>
            </b-row>
          </b-card-body>
          <template #footer>
            <b-pagination v-if="data.total > data.per_page" class="mb-0 justify-content-end" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
          </template>
        </b-card>
      </b-tab>

      <b-tab title="SEO Settings" v-if="moduleRole('seo')">
        <SEOSection />
      </b-tab>
    </b-tabs>
    <template v-else>
      <validation-observer ref="VForm" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitCareer)">
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Job Vacancy Information</h5>
            </template>
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <b-row>
                    <b-col md="4" v-if="false">
                      <b-form-group label-for="jobType">
                        <template #label>Job Type<strong class="text-danger">*</strong></template>
                        <v-select id="jobType" :reduce="el => el.value" v-model="row.mc_type" :options="mrJobType" />
                        <VValidate name="Job Type" v-model="row.mc_type" :rules="mrValidation.mc_type" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="false">
                      <b-form-group label-for="location">
                        <template #label>Location <strong class="text-danger">*</strong></template>
                        <b-form-input placeholder="e.g. Karawang, Jawa Barat" id="location" v-model="row.mc_location" @keyup="removeWildChar" @keydown="removeWildChar" />
                        <VValidate name="Location" v-model="row.mc_location" :rules="{...mrValidation.mc_location}" />
                      </b-form-group>
                    </b-col>
                    
                    <template v-if="false">
                      <div class="w-100"></div>
                      <b-col lg="3" class="mb-2">
                        <b-form-group label-for="startDate">
                          <template #label>Start Date<span class="text-danger mr5">*</span></template>
                          <div class="input-group">
                            <datepicker @input="changeStartDate" input-class="form-control transparent" v-model="row.mc_start_date" placeholder="Select Start Date"></datepicker>
                            <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                            </div>
                          </div>

                          <VValidate name="Start Date" v-model="row.mc_start_date" :rules="mrValidation.mc_end_date" />
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" class="mb-2">
                        <b-form-group label-for="startDate">
                          <template #label>End Date<span class="text-danger mr5">*</span></template>
                          <div class="input-group">
                            <datepicker input-class="form-control transparent" v-model="row.mc_end_date" :disabled-dates="{to: new Date(row.mc_start_date)}" placeholder="Select Start Date"></datepicker>
                            <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                            </div>
                          </div>

                          <VValidate name="End Date" v-model="row.mc_end_date" :rules="mrValidation.mc_end_date" />
                        </b-form-group>
                      </b-col>
                    </template>
                    <div class="w-100"></div>
                    <b-col md="4">
                      <b-form-group label-for="jobPosition">
                        <template #label>Job Name<strong class="text-danger">*</strong></template>
                        <b-form-input placeholder="e.g. Operator" id="jobPosition" v-model="row.mc_job_name" @keyup="removeWildChar" @keydown="removeWildChar" />
                        <VValidate name="Job Name" v-model="row.mc_job_name" :rules="{...mrValidation.mc_job_name}" />
                      </b-form-group>
                    </b-col>
                    <div class="w-100"></div>
                    <b-col md="6">
                      <b-form-group label-for="jobDescriptionId">
                        <template #label>Job Requierement (ID)<strong class="text-danger">*</strong></template>
                        <QuillEditor id="jobDescriptionId" :value.sync="row.mc_requirement_id" />
                        <VValidate name="Job Requierement (ID)" v-model="row.mc_requirement_id" :rules="mrValidation.mc_requirement_id" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="jobDescriptionEn">
                        <template #label>Job Requirement (EN)<strong class="text-danger">*</strong></template>
                        <QuillEditor id="jobDescriptionEn" :value.sync="row.mc_requirement_en" />
                        <VValidate name="Job Requirement (EN)" v-model="row.mc_requirement_en" :rules="mrValidation.mc_requirement_en" />

                      </b-form-group>
                    </b-col>
                    <div class="w-100"></div>
                    <b-col lg="6">
                      <b-form-group class="mt-3">
                        <label>Status<span class="text-danger mr5">*</span></label>
                        <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row.mc_is_active" />
                        <VValidate name="Status" v-model="row.mc_is_active" :rules="mrValidation.mc_is_active" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
            <template #footer>
              <div class="text-right">
                <b-button type="button" variant="secondary" @click="() => $router.push({name: $route.name})" class="btn-rounded">Cancel</b-button>
                <b-button type="submit" variant="info" class="ml-3 btn-rounded">Save Changes</b-button>
              </div>
            </template>
          </b-card>
        </b-form>
      </validation-observer>
    </template>
  </section>

  <b-modal id="modal_title" title="Title" size="lg">
    <validation-observer ref="VFormTitle" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitTitle)">
        <b-form-group>
          <template #label>Title (ID) <span class="text-danger">*</span></template>
          <b-form-input v-model="dataModalTitle.asc_title_id"></b-form-input>
          <VValidate name="Title (ID)" v-model="dataModalTitle.asc_title_id" rules="required|min:3|max:150" />
        </b-form-group>
        <b-form-group>
          <template #label>Sub Title (ID)</template>
          <b-form-textarea v-model="dataModalTitle.asc_sub_title_id"></b-form-textarea>
          <VValidate name="Sub Title (ID)" v-model="dataModalTitle.asc_sub_title_id" rules="min:3" />
        </b-form-group>
        <b-form-group>
          <template #label>Title (EN) <span class="text-danger">*</span></template>
          <b-form-input v-model="dataModalTitle.asc_title_en"></b-form-input>
          <VValidate name="Title (EN)" v-model="dataModalTitle.asc_title_en" rules="required|min:3|max:150" />
        </b-form-group>
        <b-form-group>
          <template #label>Sub Title (EN)</template>
          <b-form-textarea v-model="dataModalTitle.asc_sub_title_en"></b-form-textarea>
          <VValidate name="Sub Title (EN)" v-model="dataModalTitle.asc_sub_title_en" rules="min:3" />
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button type="button" variant="secondary" @click="$bvModal.hide('modal_title')">Cancel</b-button>
      <b-button type="button" variant="primary" @click="submitTitle">Submit</b-button>
    </template>
  </b-modal>
</b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import QuillEditor from '@/components/QuillEditor'
import Datepicker from 'vuejs-datepicker'
import SEOSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    QuillEditor,
    Datepicker,
    SEOSection,
    HeroSection
  },
  data() {
    return {
      currentTitle: '',
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      data: [],
      companyDesc: {},
      idKey: 'mc_id',
      statusKey: 'mc_is_active',
      mrJobType: [],
      mrValidation: {},
      tabIndex: 0,
      work_with_us: {
        asc_data: []
      },

      titleWorkWithUs: {},
      titleJobVacancy: {},

      dataModal: {},
      dataModalTitle: {}
    }
  },
  methods: {
    submitWorkWithUs(){
      this.$refs["VFormWorkWithUs"].validate().then(result => {
        if(!result) return false

        this.swalLoading()

        Gen.apiRest(`/do/${this.modulePage}`, {
          data: {
            type: 'update-work-with-us',
            ...this.dataModal
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$bvModal.hide('workWithUs')

          this.apiGet()
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },
    editWorkWithUs(data){
      this.$set(this, 'dataModal', { ...data })

      this.$bvModal.show('workWithUs')
    },
    changeStartDate(date) {
      if (this.row.mc_end_date && this.$moment(date).format('YYYY-MM-DD') > this.$moment(this.row.mc_end_date).format('YYYY-MM-DD')) {
        this.row.mc_end_date = null
      }
    },
    changeHero() {
      this.heroEditorOpen = !this.heroEditorOpen
    },
    autoFill(to = "", to2 = "", from = "") {
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]
    },
    submitCompanyDesc(IdFormContent = 'VFormContent') {
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateCompanyDesc'
        }, this.companyDesc),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true

            this.apiGet()
          } else {
            if (resp.response.status == 422) {
              if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormContent
      )
    },

    submitCareer() {
      this.$refs['VForm'].validate().then(success => {
        if (!success) return
        this.doSubmitCRUD('VForm', this.$refs)
      })
    },

    openModalTitle(value, slug){
      this.$set(this, 'dataModalTitle', _.clone({
        asc_slug: slug,
        asc_title_id: value.asc_title_id, 
        asc_title_en: value.asc_title_en,
        asc_sub_title_id: value.asc_sub_title_id, 
        asc_sub_title_en: value.asc_sub_title_en
      }))
      this.$bvModal.show('modal_title')
    },

    submitTitle(){
      this.$refs.VFormTitle.validate().then(result => {
        if(!result) return

        this.swalLoading()

        Gen.apiRest("/do/BoCareerPage", {
          data: { type: "submit-title", ...this.dataModalTitle },
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.$bvModal.hide('modal_title')
          this.getTitle()
        })
      })
    }
  },
  mounted() {
    this.apiGet()
  },
  watch: {
    $route() {
      this.apiGet()
    },
  }
}
</script>

<style scoped lang="css">

.content-job{
  max-height: 300px;
  overflow: hidden;
}

</style>